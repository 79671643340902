var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"400px","color":"#273142"}},[_c('v-card-title',{staticClass:"card-header"},[_c('h3',[_vm._v("Last Orders")])]),_c('v-card-text',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":5,"color":_vm.ant,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.determineDuration(item.updated_at))+" ")])]}},{key:"item.writer",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35","color":"grey"}},[(item.order_status==='bidding' || item.order_status==='open')?_c('span',[_vm._v(" 0 ")]):_c('div',[(item.writer)?_c('img',{attrs:{"src":item.writer.avatar,"alt":item.writer.nickname}}):_c('span',[_vm._v(" __ ")])])]),(item.writer)?_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.writer.nickname ? item.writer.nickname : item.writer.id)+" ")]):_vm._e()]}},{key:"item.payment_status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.payment_status ? ("" + (item.payment_status)) : '-')+" ")])]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.rating ? ((item.rating.rating) + "/5") : '-')+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }