<template>
  <v-card min-height="400px" color="#273142">
    <v-card-title class="card-header">
    <h3>Last Orders</h3>
  </v-card-title>

  <v-card-text class="pa-0" flat>
    <v-data-table
      :headers="headers"
      :items="orders"
      :items-per-page="5"
      class="transparent"
      :color="ant"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
     <template v-slot:item.updated_at="{ item }">
        <span class="text--primary"> {{ determineDuration(item.updated_at) }} </span>
      </template>

      <template v-slot:item.writer="{ item }">
        <v-avatar size=35 color="grey">
          <span v-if="item.order_status==='bidding' || item.order_status==='open'"> 0 </span>
          <div v-else>
            <img
              v-if ="item.writer"
              :src="item.writer.avatar"
              :alt="item.writer.nickname"
            />
            <span v-else> __ </span>
          </div>
        </v-avatar>
        <span class="ml-3" v-if="item.writer"> {{ item.writer.nickname ? item.writer.nickname : item.writer.id }} </span>
      </template>

      <template v-slot:item.payment_status="{ item }">
        <span > {{ item.payment_status ? `${item.payment_status}` : '-' }} </span>
      </template>
      <template v-slot:item.rating="{ item }">
        <span > {{ item.rating ? `${item.rating.rating}/5` : '-' }} </span>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import colorMxn from '@/mixins/colorMxn';
import timezoneMxn from '@/mixins/timezone_mixin';

export default {
  name: 'widthrawList',
  mixins: [colorMxn, timezoneMxn],
  data() {
    return{
       headers: [
        { text: "Order", value: "id" },
        { text: "State", value: "order_status" },
        { text: "State Changed", value: "updated_at" },
        { text: "Writer", value: "writer" },
        { text: 'Paid', value: 'payment_status'},
        { text: 'Rate', value: 'rating'},
      ],
      orders: [],
      page: 1,
      s3path: `${process.env.VUE_APP_BASE_URL}/avatar/download/`,
      loading: false,
    }
  },
  computed: {
		...mapGetters(['getSession', 'getErrors']),
	},
	mounted() {
    this.getOrders();
	},
  methods: {
    ...mapActions(['performGetActions']),
    async getOrders() {
      this.loading= true;
      const session = this.getSession;
      const payload = {
        customer_id: session.customer.id,
        page: this.page,
      }
      
      const fullPayload = {
        params: payload,
        endpoint: '/customer-orders/history',
      }

      const response = await this.performGetActions(fullPayload);
      this.loading = false;
      this.orders = response.data;
    },
    avatarURl(avatar) {
      const url = avatar ? this.s3path + avatar : 'https://cdn.vuetifyjs.com/images/john.jpg';
      return url;
    },
  }
}
</script>

<style>
.essay-green {
  color:#038c43;
}

tbody tr {
  border-bottom: none;
}
</style>